import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1, H2, H3 } from '../../components/Headings'
import Container from '../../components/Container'
// import Hero from '../../components/Hero'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { ButtonLink, ButtonA } from '../../components/Button'
import List from '../../components/List'
import EmbedSource from '../../components/EmbedSource'
import VideoWrapper from '../../components/VideoWrapper'
import Content from '../../components/Post/Content'

const PageTemplate = ({ data }) => {
  const {
    hero,
    ctaText,
    cooksDifferenceSection,
    commitmentSection,
    onSiteTrainingSection,
    footerCta,
  } = data.allWpPage.nodes[0].employeeTraining

  const jobPostingsURL = data.jobPostingsURL.edges[0].node.url

  return (
    <>
      <SEO />
      <Layout>
        {/* Hero */}
        <section tw="relative" aria-label={hero.sectionLabel}>
          <div tw="overflow-hidden h-full w-full absolute z-0">
            {hero?.background?.localFile && (
              <Img
                fluid={hero.background.localFile.childImageSharp.fluid}
                style={{ position: 'absolute' }}
                css={[
                  tw`min-w-full min-h-full max-w-full max-h-full transform -translate-x-1/2 -translate-y-1/2 opacity-75`,
                  'z-index: -1; top:50%;left:50%;',
                ]}
              />
            )}
          </div>
          <Container tw="relative py-20 z-20">
            <div tw="grid grid-cols-5 col-gap-8">
              <Img
                imgStyle={{ objectFit: 'contain' }}
                fluid={hero.cooksAcademyImage.localFile.childImageSharp.fluid}
              />
              <div tw="col-span-4">
                <H1 tw="text-5xl">{hero.headline}</H1>
                <p tw="text-xl leading-10 mb-8">{hero.paragraph}</p>
                <ButtonLink large to={jobPostingsURL}>
                  {ctaText}
                  <FontAwesomeIcon icon={faSignInAlt} tw="ml-8" />
                </ButtonLink>
              </div>
            </div>
          </Container>
        </section>

        <section aria-label={cooksDifferenceSection.sectionLabel}>
          <Container>
            {/* Cooks Difference Section */}
            <div tw="md:(grid grid-cols-2 col-gap-10) my-24">
              <div tw="mb-10 md:(pr-4 mt-0) flex flex-col justify-center">
                <H2 tw="text-5xl">{cooksDifferenceSection.headline}</H2>
                <p tw="leading-relaxed text-gray-600">
                  {cooksDifferenceSection.subtext}
                </p>
              </div>

              {/* 
                Video loads in after the poster loads, and sits on top.
              */}
              <div tw="flex flex-col justify-center">
                <VideoWrapper tw="w-full bg-gray-300">
                  <Img
                    fluid={
                      cooksDifferenceSection.video.poster.localFile
                        .childImageSharp.fluid
                    }
                    loading="lazy"
                    tw="z-0 animate-pulse" // pulses like a loader
                  />
                  <EmbedSource
                    title="the Cooks Difference"
                    src={cooksDifferenceSection.video.embed}
                    tw="z-10"
                  />
                </VideoWrapper>
              </div>
            </div>

            {/* commitmentSection */}
            <div tw="py-24">
              <H2 tw="text-5xl text-center">{commitmentSection.headline}</H2>
              <p tw="text-lg leading-relaxed text-gray-600 text-center mb-16 w-1/2 max-w-full mx-auto">
                {commitmentSection.paragraph}
              </p>

              <div tw="md:(grid grid-cols-2 col-gap-10) mb-10">
                {/* Image Grid */}
                <div tw="grid col-span-1 grid-cols-2 col-gap-4 row-gap-4">
                  {commitmentSection.images.map(({ image, colspan }) => (
                    <Img
                      fluid={image.localFile.childImageSharp.fluid}
                      css={[
                        colspan > 1
                          ? `grid-column-start 1; grid-column-end: 3;`
                          : tw`col-auto`,
                      ]}
                    />
                  ))}
                </div>
                <div>
                  <Content
                    tw="text-xl text-gray-600 leading-relaxed"
                    dangerouslySetInnerHTML={{
                      __html: commitmentSection.content,
                    }}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>

        {/* On Site Training Section */}
        <section
          tw="bg-gray-50 py-24"
          aria-label={onSiteTrainingSection.sectionLabel}
        >
          <Container>
            <div tw="mb-10  xl:(mx-20 px-20)">
              <H2 tw="text-4xl text-center mb-5">
                {onSiteTrainingSection.headline}
              </H2>
              <p tw="text-lg leading-relaxed text-gray-600 text-center">
                {onSiteTrainingSection.subtext}
              </p>
            </div>
            <div tw="mt-10 lg:(grid grid-cols-2 col-gap-10)">
              <div tw="my-10 md:(my-0)">
                <Content
                  tw="text-gray-700 text-xl leading-relaxed"
                  dangerouslySetInnerHTML={{
                    __html: onSiteTrainingSection.content,
                  }}
                />
              </div>
              <div tw="grid grid-cols-2 col-gap-4 row-gap-4">
                {onSiteTrainingSection.imageGroup.map(({ image, colspan }) => (
                  <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    css={[
                      colspan > 1
                        ? `grid-column-start 1; grid-column-end: 3;`
                        : tw`col-auto`,
                    ]}
                  />
                ))}
              </div>
            </div>
          </Container>
        </section>

        {/* Footer CTA */}
        <section aria-label={footerCta.sectionLabel}>
          <Container tw="text-center content-center pt-24">
            <H2 tw="text-5xl">{footerCta.headline}</H2>
            <p tw="text-lg leading-relaxed text-gray-600 mb-8 w-3/4 max-w-full mx-auto">
              {footerCta.paragraph}
            </p>
            <ButtonLink large to={jobPostingsURL}>
              {ctaText}
              <FontAwesomeIcon icon={faSignInAlt} tw="ml-8" />
            </ButtonLink>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    jobPostingsURL: allWpMenuItem(filter: { label: { eq: "Careers" } }) {
      edges {
        node {
          url
          title
          label
        }
      }
    }

    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        id
        employeeTraining {
          hero: employeeTrainingHero {
            headline: employeeTrainingHeroHeadline
            paragraph: employeeTrainingHeroParagraph
            sectionLabel: employeeTrainingHeroSectionLabel
            cooksAcademyImage: employeeTrainingHeroCooksAcademyImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            background: employeeTrainingHeroBackgroundImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ctaText: employeeTrainingCtaText
          cooksDifferenceSection: employeeTrainingCooksDifferenceSection {
            headline: cooksDifferenceSectionHeadline
            subtext: cooksDifferenceSectionParagraph
            sectionLabel: cooksDifferenceSectionSectionLabel
            video: cooksDifferenceSectionCooksDifferenceVideo {
              embed
              poster {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          onSiteTrainingSection: employeeTrainingOnSiteTrainingSection {
            headline: onSiteTrainingSectionHeadline
            subtext: onSiteTrainingSectionSubtext
            sectionLabel: onSiteTrainingSectionSectionLabel
            imageGroup: onSiteTrainingImageList {
              image: onSiteTrainingPhoto {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              colspan: onSiteTrainingColumnSpan
            }
            images: onSiteTrainingSectionImages {
              image: onSiteTrainingSectionImagesImage {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              colspan: onSiteTrainingSectionImagesColspan
            }
            content: onSiteTrainingSectionContent
          }
          commitmentSection: employeeTrainingCommitmentSection {
            headline: commitmentSectionHeadline
            paragraph: commitmentSectionParagraph
            images: commitmentSectionImages {
              image: commitmentSectionImagesImage {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              colspan
            }
            sectionLabel: commitmentSectionSectionLabel
            content: commitmentSectionContent
          }

          footerCta: employeeTrainingFooterCta {
            headline: footerCtaHeadline
            paragraph: footerCtaParagraph
            sectionLabel: footerCtaSectionLabel
          }
        }
      }
    }
  }
`
